import queryString from 'query-string';
import moment from 'moment';
import LABELS from './labels';
import enums from '../enums';
import { fetchOrThrow } from './api';
import { SLO_MAPPING_TYPES_MAP } from './slo-mapping-api-utils';
import distributionCenterApi from './distribution-center-api';
import coverageSpreadsheet from './coverage-spreadsheet/coverage-spreadsheet';
import regionApi from './region-api';
import sloMappingApi from './slo-mapping-api';
import rulesApi from './rules-api/rules-api';
import { isLastMileCompanyLoggi } from '../view/molecules/postal-codes-table/utils';
import { convertObjectToTimeString } from '../utils/format-time';
import {
  normalize,
  denormalize,
  isDistributionCenterRequest,
  isRegionRequest,
  isRulesRequest,
  isLastMileCompanyRequest,
  isServiceTypeRequest
} from './api-utils';
import { DEFAULT_INITIAL_PAGE, DEFAULT_DOCUMENTS_PER_PAGE } from './constants';
import { getEmail } from '../utils/logged-user-data';
import { convertMoneyProtoToDecimal } from '../utils/currency-format';

const ENDPOINT_URL = `${process.env.REACT_APP_ANNOTATOR_URL}/api/v1`;
const ENDPOINT_URL_V2 = `${process.env.REACT_APP_ANNOTATOR_URL}/api/v2`;
const GROUP_INFO_URL = `${ENDPOINT_URL}/group`;
const GROUP_INFO_URL_V2 = `${ENDPOINT_URL_V2}/group`;
const POSTAL_CODE_GROUP_TYPES_MAP = {
  'service-type': enums.PostalCodeGroupType.POSTAL_CODE_GROUP_TYPE_SERVICE_TYPE,
  'last-mile-company':
    enums.PostalCodeGroupType.POSTAL_CODE_GROUP_TYPE_LAST_MILE_COMPANY,
  'pricing-region':
    enums.PostalCodeGroupType.POSTAL_CODE_GROUP_TYPE_PRICING_REGION,
  'service-region':
    enums.PostalCodeGroupType.POSTAL_CODE_GROUP_TYPE_SERVICE_REGION
};

const _slugify = name => {
  let str = name.trim();
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i += 1) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

const _parseLastMileCompanyInfoDropOffAmount = postalCodeGroup => {
  const newPostalCodeGroup = { ...postalCodeGroup };
  if (newPostalCodeGroup.lastMileCompanyInfo?.dropOffAmount) {
    newPostalCodeGroup.lastMileCompanyInfo.dropOffAmount = convertMoneyProtoToDecimal(
      newPostalCodeGroup.lastMileCompanyInfo.dropOffAmount
    );
  }

  return newPostalCodeGroup;
};

const _readCsv = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
};

const _buildRanges = resultCsv => {
  const lines = resultCsv.split('\n');
  return lines
    .filter((_, index) => index !== 0)
    .map(line => {
      const pricingRegion = line.split(',');
      return {
        name: pricingRegion[0],
        start: {
          code: pricingRegion[1]
        },
        end: {
          code: pricingRegion[2]
        },
        rangeType: enums.rangeType.RANGE_TYPE_DEFAULT
      };
    });
};

const _importRedispatchSlo = async args => {
  const {
    action: { attachment }
  } = args.data;
  const endpoint = `${GROUP_INFO_URL}/import-redispatch-slo`;

  const formData = new FormData();
  formData.append('fileUpload', attachment.rawFile);

  const response = await fetchOrThrow(endpoint, {
    method: 'POST',
    body: formData,
    headers: {}
  });

  return { data: response };
};

const _publishCoverage = async args => {
  const { originServiceRegionIds, targetDateTimestampInMs } = args.data;
  const endpoint = `${ENDPOINT_URL}/coverage-mapping/publish`;
  const userEmail = getEmail();
  const requestBody = {
    originServiceRegionIds,
    targetDateTimestampInMs
  };

  const response = await fetchOrThrow(endpoint, {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: {
      'User-Email': userEmail,
      'Content-Type': 'application/json'
    }
  });
  const data = { id: 0, message: response };

  return { data };
};

const _reAnnotationPromisedDate = async args => {
  const {
    action: { attachment },
    reAnnotation
  } = args.data;
  const endpoint = `${ENDPOINT_URL}/re-annotate/promised-date`;

  const formData = new FormData();
  const params = {
    ...reAnnotation,
    userEmail: getEmail(),
    operationType: 'PROMISED_DATE_RE_ANNOTATION_TYPE_ADD_DAYS'
  };
  formData.append('fileUpload', attachment.rawFile);
  formData.append('params', JSON.stringify(params));

  const response = await fetchOrThrow(endpoint, {
    method: 'PATCH',
    body: formData,
    headers: {}
  });

  const data = { id: 0, ...response };
  return { data };
};

const _createHubs = async (distributionCenter, address, lmcId) => {
  const endpoint = `${ENDPOINT_URL}/hub`;

  const workingTime = JSON.stringify(distributionCenter.working_time);
  const hub = {
    distributionCenter: { ...distributionCenter, working_time: workingTime },
    address
  };

  if (lmcId) {
    hub.lastMileCompanies = [{ id: lmcId }];
  }

  const request = {
    hubs: [hub]
  };

  return fetchOrThrow(endpoint, {
    method: 'POST',
    body: JSON.stringify(request)
  });
};

const _createLmc = async (
  lastMileCompanyInfo,
  postalCodes,
  hubId,
  versioningStatus,
  effectiveOnTimeAsInt
) => {
  const endpoint = `${GROUP_INFO_URL}/last-mile-company`;
  const postalCodeGroup = {
    lastMileCompanyInfo: { active: false, ...lastMileCompanyInfo },
    type: POSTAL_CODE_GROUP_TYPES_MAP['last-mile-company'],
    postalCodes,
    ...(versioningStatus && { versioningStatus }),
    ...(effectiveOnTimeAsInt && { effectiveOnTimeAsInt })
  };
  const hubs = hubId ? [{ distributionCenter: { id: hubId } }] : [];

  const request = {
    postalCodeGroup,
    hubs
  };

  return fetchOrThrow(endpoint, {
    method: 'POST',
    body: JSON.stringify(request)
  });
};

const _createBase = async args => {
  const {
    distributionCenter,
    address,
    lastMileCompanyInfo,
    postalCodes,
    versioningStatus,
    effectiveOnTimeAsInt
  } = args.data;
  const lmcId = lastMileCompanyInfo.id;

  const hubs = await _createHubs(distributionCenter, address, lmcId);

  if (!hubs.hubs || !hubs.hubs.length) {
    throw Error(LABELS.ALERTS.CREATE_DC_ERROR);
  }

  // Associated DC with LOGGI Agencies
  const postalCodeGroupInfo = { groupType: lastMileCompanyInfo.companyType };
  if (isLastMileCompanyLoggi(postalCodeGroupInfo) && lmcId) {
    return { data: hubs };
  }

  // Create new Lmc
  const hubId = hubs.hubs[0].distributionCenter.id;
  const lmc = await _createLmc(
    lastMileCompanyInfo,
    postalCodes,
    hubId,
    versioningStatus,
    effectiveOnTimeAsInt
  );
  return { data: lmc };
};

const _createDeliveryEarningsTable = async args => {
  const {
    createDeliveryEarningsTable: { attachment }
  } = args.data;

  const formData = new FormData();
  formData.append('fileUpload', attachment.rawFile);

  const endpoint = `${ENDPOINT_URL}/delivery-earnings-table`;

  const response = await fetchOrThrow(endpoint, {
    method: 'POST',
    body: formData,
    headers: {}
  });

  const data = normalize({ _id: 0, ...response });

  return { data };
};

const _getRegionName = serviceRegion =>
  serviceRegion && serviceRegion.serviceRegionInfo.name;

const _getServiceTypeName = serviceType =>
  serviceType && serviceType.serviceTypeInfo.name;

const _getCityRegionName = cityRegion => cityRegion && cityRegion.name;

const getList = async (groupName, args = {}) => {
  if (SLO_MAPPING_TYPES_MAP[groupName]) {
    return sloMappingApi.getList(groupName, args);
  }

  if (isDistributionCenterRequest(groupName)) {
    return distributionCenterApi.getList(args);
  }

  if (isRegionRequest(groupName)) {
    return regionApi.getList(args);
  }

  if (isRulesRequest(groupName)) {
    return rulesApi.getList(args);
  }

  const {
    pagination = {
      page: DEFAULT_INITIAL_PAGE,
      perPage: DEFAULT_DOCUMENTS_PER_PAGE
    },
    filter = {},
    sort = { field: '', order: '' }
  } = args;

  const {
    zipCode = '',
    rangeTypes = [],
    configurationId = '',
    cityTypes = [],
    serviceRegion = [],
    loggiName = '',
    redispatchName = ''
  } = filter;

  const params = {
    name: filter.name && `"${filter.name}"`,
    page: pagination.page,
    'results-per-page': pagination.perPage
  };

  if (zipCode.length === 8) {
    params['range-start'] = zipCode;
    params['range-end'] = zipCode;
  }

  if (rangeTypes.length) {
    params['range-types'] = rangeTypes;
  }

  if (configurationId.length) {
    params.configurationId = configurationId;
  }

  if (loggiName.length) {
    params.loggiName = loggiName;
  }

  if (redispatchName.length) {
    params.redispatchName = redispatchName;
  }

  if (sort.field && sort.order) {
    params.sort = sort.field;
    params.order = sort.order;
  }

  if (cityTypes.length) {
    params['city-types'] = cityTypes;
  }

  if (serviceRegion.length) {
    params['service-region-id'] = serviceRegion;
  }

  const filters = queryString.stringify(params, { sort: false });

  const response = await fetchOrThrow(
    `${GROUP_INFO_URL}/${groupName}?${filters}`
  );

  const { postalCodeGroupInfoElement = [], total = '0' } = response;

  const data = postalCodeGroupInfoElement.map(normalize);

  data.map(_parseLastMileCompanyInfoDropOffAmount);

  return {
    data,
    total: parseInt(total, 10)
  };
};

const getOneServiceRegion = async (groupName, args) => {
  const { id } = args;
  const endpoint = `${GROUP_INFO_URL_V2}/${groupName}/${id}`;

  const response = await fetchOrThrow(endpoint);

  const { parentServiceRegionGroupInfo, serviceRegion } = response;
  serviceRegion.serviceRegionInfo.parentServiceRegionName =
    parentServiceRegionGroupInfo && parentServiceRegionGroupInfo.name;

  const normalizedResponse = normalize(serviceRegion);
  return { data: { id, ...normalizedResponse } };
};

const getOneServiceType = async (groupName, args) => {
  const { id } = args;
  const endpoint = `${GROUP_INFO_URL_V2}/${groupName}/${id}`;

  const response = await fetchOrThrow(endpoint);

  const {
    serviceRegion,
    serviceType,
    baseCityServiceType,
    cityRegion
  } = response;

  serviceType.serviceTypeInfo.serviceRegionName = _getRegionName(serviceRegion);
  serviceType.serviceTypeInfo.baseCityServiceTypeName = _getServiceTypeName(
    baseCityServiceType
  );
  serviceType.serviceTypeInfo.cityRegionName = _getCityRegionName(cityRegion);

  const normalizedResponse = normalize(serviceType);

  return { data: { id, ...normalizedResponse } };
};

const getOne = async (groupName, args) => {
  if (groupName === enums.resources.SERVICE_REGION) {
    return getOneServiceRegion(groupName, args);
  }

  if (groupName === enums.resources.SERVICE_TYPE) {
    return getOneServiceType(groupName, args);
  }

  const { id } = args;

  if (SLO_MAPPING_TYPES_MAP[groupName]) {
    return sloMappingApi.getOne(id);
  }

  if (isDistributionCenterRequest(groupName)) {
    return distributionCenterApi.getOne(id);
  }

  if (isRegionRequest(groupName)) {
    return regionApi.getOne(id);
  }

  if (isRulesRequest(groupName)) {
    return rulesApi.getOne(id);
  }

  const endpoint = `${GROUP_INFO_URL}/${groupName}/${id}`;

  const response = await fetchOrThrow(endpoint);

  if (isLastMileCompanyRequest(groupName)) {
    const hasPickupWorkingHours =
      response.lastMileCompanyInfo &&
      response.lastMileCompanyInfo.pickupWorkingHours;

    if (hasPickupWorkingHours) {
      const {
        openAt,
        closeAt
      } = response.lastMileCompanyInfo.pickupWorkingHours;
      response.lastMileCompanyInfo.pickupWorkingHours = {
        openAt: convertObjectToTimeString(openAt),
        closeAt: convertObjectToTimeString(closeAt)
      };
    }

    const hasPickupWorkingWeekdays =
      response.lastMileCompanyInfo &&
      response.lastMileCompanyInfo.pickupWorkingWeekdays;

    if (hasPickupWorkingWeekdays) {
      const { pickupWorkingWeekdays } = response.lastMileCompanyInfo;

      response.lastMileCompanyInfo.pickupWorkingWeekdays = pickupWorkingWeekdays.map(
        day => enums.DAY_OF_WEEK[day]
      );
    }

    if (response.lastMileCompanyInfo.dropOffAmount) {
      response.lastMileCompanyInfo.dropOffAmount = convertMoneyProtoToDecimal(
        response.lastMileCompanyInfo.dropOffAmount
      );
    }
  }

  const data = normalize(response);

  return { data: { id, ...data } };
};

const update = async (groupName, args) => {
  if (SLO_MAPPING_TYPES_MAP[groupName]) {
    return sloMappingApi.update(args);
  }

  if (isDistributionCenterRequest(groupName)) {
    return distributionCenterApi.updateDeadlineOffset(args);
  }

  if (isRegionRequest(groupName)) {
    return regionApi.update(args);
  }

  if (isRulesRequest(groupName)) {
    return rulesApi.update(args);
  }

  const { data } = args;
  const { id } = data;
  const endpoint = `${GROUP_INFO_URL}/${groupName}`;
  const postalCodeGroup = denormalize(data);
  const isNewProgrammedDocument =
    postalCodeGroup.versioningStatus ===
      enums.versioningStatusType.PROGRAMMED &&
    postalCodeGroup.isNewProgrammedDocument;

  const fetchArguments = {
    endpoint: !isNewProgrammedDocument ? `${endpoint}/${id}` : endpoint,
    options: {
      method: !isNewProgrammedDocument ? 'PUT' : 'POST',
      body: JSON.stringify({ postalCodeGroup })
    }
  };

  const response = await fetchOrThrow(
    fetchArguments.endpoint,
    fetchArguments.options
  );

  return { data: { id, ...response } };
};

const CUSTOM_ACTIONS = {
  [enums.customResources.IMPORT_REDISPATCH_SLO]: _importRedispatchSlo,
  [enums.customResources.CREATE_BASE]: _createBase,
  [enums.customResources
    .CREATE_DELIVERY_EARNINGS_TABLE]: _createDeliveryEarningsTable,
  [enums.customResources.GENERATE_COVERAGE_SPREADSHEET]:
    coverageSpreadsheet.generateSpreadsheet,
  [enums.customResources.PUBLISH_COVERAGE]: _publishCoverage,
  [enums.customResources.RE_ANNOTATION_PROMISED_DATE]: _reAnnotationPromisedDate
};

const create = async (groupName, args) => {
  if (CUSTOM_ACTIONS[groupName]) {
    return CUSTOM_ACTIONS[groupName](args);
  }

  if (SLO_MAPPING_TYPES_MAP[groupName]) {
    return sloMappingApi.create(groupName, args);
  }

  if (isRegionRequest(groupName)) {
    return regionApi.create(args);
  }

  if (isLastMileCompanyRequest(groupName)) {
    const lmc = await _createLmc(
      args.data.lastMileCompanyInfo,
      args.data.postalCodes,
      null,
      args.data.versioningStatus,
      args.data.effectiveOnTimeAsInt
    );

    return { data: { id: 0, ...lmc } };
  }

  const {
    serviceTypeInfo,
    pricingRegionInfo,
    serviceRegionInfo,
    postalCodes,
    versioningStatus,
    effectiveOnTimeAsInt
  } = args.data;
  const endpoint = `${GROUP_INFO_URL}/${groupName}`;

  const postalCodeGroup = {
    type: POSTAL_CODE_GROUP_TYPES_MAP[groupName],
    ...(versioningStatus && { versioningStatus }),
    ...(effectiveOnTimeAsInt && { effectiveOnTimeAsInt })
  };

  let id = '';

  if (serviceTypeInfo) {
    serviceTypeInfo.id = _slugify(serviceTypeInfo.name);

    id = serviceTypeInfo.id;

    postalCodeGroup.serviceTypeInfo = serviceTypeInfo;
  } else if (pricingRegionInfo) {
    postalCodeGroup.pricingRegionInfo = pricingRegionInfo;

    id = pricingRegionInfo.id;

    const csv = await _readCsv(pricingRegionInfo.attachment.rawFile);

    postalCodeGroup.postalCodes = {};
    postalCodeGroup.postalCodes.ranges = _buildRanges(csv);
  } else if (serviceRegionInfo) {
    serviceRegionInfo.id = _slugify(serviceRegionInfo.name);
    postalCodeGroup.serviceRegionInfo = serviceRegionInfo;
  } else {
    throw Error(LABELS.ALERTS.INVALID_POSTAL_CODE_GROUP_INFO);
  }

  if (postalCodes) {
    postalCodeGroup.postalCodes = postalCodes;
  }

  const response = await fetchOrThrow(endpoint, {
    method: 'POST',
    body: JSON.stringify({ postalCodeGroup })
  });

  return { data: { id, ...response } };
};

const patch = async (groupName, args) => {
  const { id, data, persist = false } = args;

  const params = queryString.stringify({
    persist
  });

  const endpoint = `${GROUP_INFO_URL}/${groupName}/${id}?${params}`;

  const postalCodeGroup = denormalize(data);

  const response = await fetchOrThrow(endpoint, {
    method: 'PATCH',
    body: JSON.stringify(postalCodeGroup)
  });

  return { id, data: response };
};

const reAnnotate = async (hours, minutes) => {
  if (!hours && !minutes) {
    throw Error(LABELS.ALERTS.INVALID_INPUT_REANNOTATION);
  }

  const endpoint = `${ENDPOINT_URL}/re-annotate`;

  const startTimestamp = moment().unix();

  const timeInSeconds = hours * 60 * 60 + minutes * 60;

  const endTimestamp = startTimestamp - timeInSeconds;

  const request = {
    start_at_timestamp: startTimestamp,
    end_at_timestamp: endTimestamp
  };

  const response = await fetchOrThrow(endpoint, {
    method: 'POST',
    body: JSON.stringify(request)
  });

  return { data: response };
};

const getAllSummariesFromDeliveryTable = async () => {
  const endpoint = `${ENDPOINT_URL}/delivery-earnings-table/summaries`;

  const response = await fetchOrThrow(endpoint);

  return { data: response };
};

const getLabelsFromDeliveryTable = async deliveryTableName => {
  const endpoint = `${ENDPOINT_URL}/delivery-earnings-table/${deliveryTableName}/labels`;

  const response = await fetchOrThrow(endpoint);

  return { data: response };
};

const getRegions = async (args = {}) => {
  const {
    resultsPerPage = 10,
    types = [enums.regionTypes.REGION_TYPE_CITY],
    name,
    ...restOfArgs
  } = args;

  const filters = {
    'results-per-page': resultsPerPage,
    types,
    ...(name && { name: `"${name}"` }),
    ...restOfArgs
  };

  const queryParams = queryString.stringify(filters, { sort: false });
  const endpoint = `${ENDPOINT_URL}/region?${queryParams}`;

  const response = await fetchOrThrow(endpoint);

  return { data: response };
};

const getRegion = async regionId => {
  const endpoint = `${ENDPOINT_URL}/region/${regionId}`;

  const response = await fetchOrThrow(endpoint);

  return { data: response };
};

const listDocumentVersions = async (groupName, referenceId) => {
  const params = {
    'versioning-reference-id': referenceId,
    page: DEFAULT_INITIAL_PAGE,
    'results-per-page': 0
  };

  const filters = queryString.stringify(params, { sort: false });
  const endpoint = `${ENDPOINT_URL}/group/${groupName}?${filters}`;

  const response = await fetchOrThrow(endpoint);
  const { postalCodeGroupInfoElement = [] } = response;

  const versionsList = postalCodeGroupInfoElement.map(normalize);

  return versionsList.map(
    ({ id, versioningStatus, effectiveOnTimeAsInt, expiredAtTimeAsInt }) => ({
      id,
      versioningStatus,
      effectiveOnTimeAsInt,
      expiredAtTimeAsInt
    })
  );
};

/**
 * Placeholder functions for endpoints not yet implemented, to comply with
 * the DataProvider specification from React-Admin
 *
 * https://marmelab.com/react-admin/DataProviders.html#writing-your-own-data-provider
 */

const updateMany = async (groupName, args) => {
  if (isRulesRequest(groupName)) {
    return rulesApi.updateMany(args);
  }

  return null;
};

const deleteOnePostalCodeGroup = async (groupName, id) => {
  const url = `${GROUP_INFO_URL}/${groupName}/${id}`;
  await fetchOrThrow(url, {
    method: 'DELETE'
  });
  return { data: { id } };
};

const deleteOne = async (groupName, args) => {
  const { id } = args;

  if (isRegionRequest(groupName)) {
    return regionApi.deleteOne(id);
  }

  if (isRulesRequest(groupName)) {
    return rulesApi.deleteOne(id);
  }

  if (isLastMileCompanyRequest(groupName) || isServiceTypeRequest(groupName)) {
    return deleteOnePostalCodeGroup(groupName, id);
  }

  return SLO_MAPPING_TYPES_MAP[groupName] && sloMappingApi.deleteOne(id);
};

export default {
  getList,
  getOne,
  update,
  create,
  updateMany,
  delete: deleteOne,
  fetchOrThrow,
  patch,
  reAnnotate,
  getAllSummariesFromDeliveryTable,
  getLabelsFromDeliveryTable,
  getRegions,
  getRegion,
  listDocumentVersions
};
