const LABELS = {
  PAGE_TITLE: {
    TITLE: 'Publicar Abrangência'
  },
  SECTIONS: {
    ORIGINS: 'Origens',
    REFERENCE_DATE_TIME: 'Data de referência'
  },
  SECTION_SUBTITLE: {
    ORIGINS: 'Selecione uma ou mais origens para publicar a abrangência.',
    REFERENCE_DATE_TIME: 'Selecione a data de referência para publicação.'
  },
  FORM_DATA: {
    DATE_IN_SECONDS: 'dateInSeconds'
  },
  ACTIONS: {
    PUBLISH: 'Publicar'
  },
  INPUT_TEXT: {
    DATE_LABEL: 'Data'
  },
  DATA_TEST: {
    DATE_PICKER_DIALOG_PUBLISH_COVERAGE: 'date-picker-dialog-publish-coverage'
  },
  AFTER_ACTION: {
    SUCCESS: 'A publicação da abrangência foi agendada com sucesso!'
  },
  WARNINGS: {
    MANDATORY: 'Obrigatório',
    INVALID_DATE: 'Data inválida'
  }
};

export default LABELS;
